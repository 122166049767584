import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/ui/Button";

import DialogWrapper from "./Dialog";
import DialogTitle from "./DialogTitle";
import DialogContent from "./DialogContent";

const Loader = () => (
  <div style={{ padding: 50 }}>
    <CircularProgress size={50} />
  </div>
);

const StyledDialogActions = styled(DialogActions)((props) => ({
  borderTop: props.showBorders
    ? `1px solid ${props.theme.palette.dividerFaint}`
    : "none",
}));

function Dialog({
  borderOptions,
  children,
  className,
  confirmButtonText,
  cancelButtonText,
  loading,
  open = false,
  onConfirm,
  onCancel,
  onClose,
  style,
  title,
}) {
  const confirmButtonTxt = confirmButtonText ?? "Confirm";
  const cancelButtonTxt = cancelButtonText ?? "Cancel";

  const handleClose = (event) => {
    onClose?.(event);
  };

  const handleConfirm = (event) => {
    onConfirm?.(event);
    // if (onClose) onClose();
  };

  const handleCancel = (event) => {
    onCancel?.(event);
    // if (onClose) onClose();
  };

  const renderContent = () => {
    if (loading) return <Loader />;

    return (
      <>
        <DialogTitle showBorders={borderOptions?.title}>{title}</DialogTitle>

        <DialogContent>{children}</DialogContent>

        <StyledDialogActions showBorders={borderOptions?.actions}>
          {onCancel && (
            <Button variant="low-emphasis" onClick={handleCancel}>
              {cancelButtonTxt}
            </Button>
          )}
          <Button variant="contained" onClick={handleConfirm}>
            {confirmButtonTxt}
          </Button>
        </StyledDialogActions>
      </>
    );
  };

  return (
    <DialogWrapper
      className={className}
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      style={style}
    >
      {renderContent()}
    </DialogWrapper>
  );
}

export default Dialog;
export { DialogWrapper, DialogTitle, DialogContent };
